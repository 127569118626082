// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:eafedb1d-c903-495d-8b8c-dcc9361e58bf",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_u3N587wDo",
    "aws_user_pools_web_client_id": "5jkijbnhmtmussq046dbo90999",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://alsa4l4pqzb5xeaolshfk2jmnu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "buildingonline9c71bf2e33864374b0c60135d4a786d0-master",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "88dc906c513a4a96946bab2046eda8aa",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
