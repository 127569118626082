import React, {useState, useEffect} from 'react';

import WorkStatus from '../components/WorkStatus';
import Navbar from '../components/lib/Navbar';
import { API, graphqlOperation } from 'aws-amplify';
import getUsername from '../impureFunctions/getUsername';
import getUserDetails from '../impureFunctions/getUserDetails';
import { updateUser } from '../graphql/mutations';
import stream from 'getstream';

class updateUserStatus {
    constructor(history){
        this._history = history;
    }
    
    updateDatabase(username, workType, availability, availabilityDate, postToNetwork){
        return API.graphql(graphqlOperation(updateUser, {input: {
            id: username,
            workType: workType,
            availability: availability,
            availabilityDate: availabilityDate,
            postToNetwork: postToNetwork
        }}));
    }

    async postToFeed(username, feedAuthToken, message){
        const client = await stream.connect('ghh53eh4dzjg', feedAuthToken,  '57538');
        const userId = client.userId;
        const userFeed = await client.feed("user", userId, feedAuthToken);
        return userFeed.addActivity({
          "actor": client.user(userId).ref(),
          "verb": "post",
          "object": message
        });
    }

    goToHome(){
        return this._history.push({pathname: '/menu'});
    }

    async execute(updatedStatus, userDetails, message, username){
        const {workType, availability, availabilityDate, postToNetwork} = updatedStatus;
        const {feedAuthToken} = userDetails;
        
        await this.updateDatabase(username, workType, availability, availabilityDate, postToNetwork);
        if(postToNetwork === 'true'){
            await this.postToFeed(username, feedAuthToken, message);
        }
        return this.goToHome();   
    }
}

export default function WorkStatusScreen({history}) {

    const [username, setUsername] = useState(null);
    const [userDetails, setUserDetails] = useState({
        workType: null,
        availability: null,
        availabilityDate: null,
        postToNetwork: null
    });

    useEffect(() => {
        const fetchData = async () => {
            const username = await getUsername();
            setUsername(username);
            setUserDetails(await getUserDetails(username));
        };
        fetchData();

        if(window.Tawk_API) window.Tawk_API.hideWidget();
        
    }, []);

    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
            <Navbar history={history}/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}>
                <WorkStatus
                    style={{minHeight: '100vh', width: '90vw'}}
                    details={userDetails}
                    submitEvent={(updatedStatus, message) => new updateUserStatus(history).execute(updatedStatus, userDetails, message, username)}
                />
            </div>
        </div>
    );
}