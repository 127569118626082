import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ConfirmDelete({show, onHide, removePost}){
    return (
        <Modal centered show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this post?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" onClick={removePost}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
    );
}

ConfirmDelete.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  removePost: PropTypes.func.isRequired
};