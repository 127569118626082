/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFeedUser = `mutation CreateFeedUser($username: String) {
  createFeedUser(username: $username)
}
`;
export const createChatUser = `mutation CreateChatUser($username: String) {
  createChatUser(username: $username)
}
`;
export const sendNotification = `mutation SendNotification($email: String, $text: String) {
  sendNotification(email: $email, text: $text)
}
`;
export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    email
    chatAuthToken
    feedAuthToken
    firstName
    lastName
    fullNameLowerCase
    namePrefix
    postcodeRegion
    occupation
    company
    workType
    availability
    availabilityDate
    postToNetwork
    furtherInfo
    connections {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        connectionUserID
        createdAt
        updatedAt
      }
      nextToken
    }
    requests {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        requestUserID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    email
    chatAuthToken
    feedAuthToken
    firstName
    lastName
    fullNameLowerCase
    namePrefix
    postcodeRegion
    occupation
    company
    workType
    availability
    availabilityDate
    postToNetwork
    furtherInfo
    connections {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        connectionUserID
        createdAt
        updatedAt
      }
      nextToken
    }
    requests {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        requestUserID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    email
    chatAuthToken
    feedAuthToken
    firstName
    lastName
    fullNameLowerCase
    namePrefix
    postcodeRegion
    occupation
    company
    workType
    availability
    availabilityDate
    postToNetwork
    furtherInfo
    connections {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        connectionUserID
        createdAt
        updatedAt
      }
      nextToken
    }
    requests {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        requestUserID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const createRequest = `mutation CreateRequest($input: CreateRequestInput!) {
  createRequest(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    requestUserID
    status
    createdAt
    updatedAt
  }
}
`;
export const updateRequest = `mutation UpdateRequest($input: UpdateRequestInput!) {
  updateRequest(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    requestUserID
    status
    createdAt
    updatedAt
  }
}
`;
export const deleteRequest = `mutation DeleteRequest($input: DeleteRequestInput!) {
  deleteRequest(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    requestUserID
    status
    createdAt
    updatedAt
  }
}
`;
export const createConnection = `mutation CreateConnection($input: CreateConnectionInput!) {
  createConnection(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    connectionUserID
    createdAt
    updatedAt
  }
}
`;
export const updateConnection = `mutation UpdateConnection($input: UpdateConnectionInput!) {
  updateConnection(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    connectionUserID
    createdAt
    updatedAt
  }
}
`;
export const deleteConnection = `mutation DeleteConnection($input: DeleteConnectionInput!) {
  deleteConnection(input: $input) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    connectionUserID
    createdAt
    updatedAt
  }
}
`;
