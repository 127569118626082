import getUsername from './getUsername';
import { API, graphqlOperation } from 'aws-amplify';
import { getUser } from '../graphql/queries';

export default async function getConnectionRequests(){
    const username = await getUsername();
    const newRequestsResponse = (await API.graphql(graphqlOperation(getUser, {id: username}))).data.getUser.requests;
    return {
        nextToken: newRequestsResponse.nextToken,
        newRequests: newRequestsResponse.items.filter(x => x.status === "Received"),
        hasMore: (newRequestsResponse.nextToken === null) ? false : true
    };
}