import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {ListGroup, Badge} from 'react-bootstrap';
import { Link } from "react-router-dom";
import ContactPicture from '../../../impureComponents/ContactPicture';

const UserListItem = ({contact}) => {

  const {id, firstName, lastName, occupation, company} = contact;

  return(
    <Link
      to={{pathname: "/contact", search: `?contact=${contact.id}` }}
      style={{ textDecoration: 'none', marginBottom: 5}}
    >
      {contact.request === 'received' && <Badge variant="success" style={{marginRight: -10}}>CONNECTION REQUEST</Badge>}
      <ListGroup.Item action style={{display:'flex', flexDirection: 'row', borderRadius: "15px"}}>

        <div style={{ minWidth: '80px', width: '80px', height: '80px',  marginRight: "50px"}}>
          <ContactPicture id={id}/>
        </div>

        <div style={{lineHeight: 0.9}}>
          <p style={{marginBottom: "10px"}}>{firstName + ' ' + lastName}</p>
          <p style={{marginBottom: "10px", color:"grey"}}>{occupation}</p>
          <p style={{marginBottom: "5px"}}><b>{company}</b></p>    
        </div>
      </ListGroup.Item>
    </Link>

  );
};

export default function AddContactUserList({users, loadFunc, hasMore}) {

    const items = users.map((contact, index) => <UserListItem key={index} contact={contact}/>);

    return (
        <InfiniteScroll
          style={{padding: 10}}
          pageStart={0}
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={<div className="loader" key={0}>Loading ...</div>}
        >
          <ListGroup>
            {items}
          </ListGroup>
        </InfiniteScroll>        
    );
}

AddContactUserList.defaultProps = {
  contacts: [],
  loadFunc: () => [],
  hasMore: false
};