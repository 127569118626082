import React, {useState, useEffect} from 'react';
import ContactDetails from '../components/Contact/Details';
import ContactOptions from '../components/Contact/Options';
import createConnectionRequest from '../impureFunctions/createConnectionRequest';
import acceptConnectionRequest from '../impureFunctions/acceptConnectionRequest';
import declineConnectionRequest from '../impureFunctions/declineConnectionRequest';
import goToContactsScreen from '../impureFunctions/goToContactsScreen';
import goToAddnewcontactScreen from '../impureFunctions/goToAddnewcontactScreen';
import doesConnectionExist from '../impureFunctions/doesConnectionExist';
import getConnectionRequest from '../impureFunctions/getConnectionRequest';
import getUsername from '../impureFunctions/getUsername';
import getUserDetails from '../impureFunctions/getUserDetails';
import Navbar from '../components/lib/Navbar';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import queryString from 'query-string';
import ContactPicture from '../impureComponents/ContactPicture';
import createConnectionRequestText from '../functions/Contact/createConnectionRequestText';
import createConnectionAcceptedText from '../functions/Contact/createConnectionAcceptedText';

export default function Contact({location, history}) {

    const contactUsername = queryString.parse(location.search).contact;

    const [showLoader, setShowLoader] = useState(false);
    const [username, setUsername] = useState(null);
    const [myDetails, setMyDetails] = useState(null);
    const [contact, setContact] = useState(null);
    const [relationship, setRelationship] = useState(null);

    useEffect(() => {
        async function findRelationship(contactUsername){
            const username = await getUsername();
            setUsername(username);
            setMyDetails(await getUserDetails(username));            
            
            const contactDetails = await getUserDetails(contactUsername);
            setContact(contactDetails);

            if(await doesConnectionExist(username, contactUsername)){
                setRelationship("Connected");
                return;
            }

            const connectionRequest = await getConnectionRequest(username, contactUsername);
            if(connectionRequest.exists){
                setRelationship(connectionRequest.status);
                return;
            }            
            
            return;
        }

        findRelationship(contactUsername);
        
        if(window.Tawk_API) window.Tawk_API.hideWidget();

    }, [contactUsername]);

    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
            <Navbar history={history}/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}>
                {showLoader || contact == null ?
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '90vw'}}>
                      <Loader
                         type="Puff"
                         color="#00BFFF"
                         height={150}
                         width={150}
                      />
                    </div>
                :
                    <div style={{minHeight: '100vh', width: '100vw'}}>
                    
                        <div style={{minHeight: '100vh', width: '100vw'}}>
                           
                            <div style={{maxWidth: 750, margin: "0 auto"}}>
                                <div style={{margin: 50, marginBottom: -45, maxWidth: "25vw", maxHeight: "25vw", width: '150px', height: '150px'}}>
                                    <ContactPicture id={contact.id}/>
                                </div>
                            </div>
                            
                            <ContactDetails contact={contact}>

                                <ContactOptions
                                    relationship={relationship}
                                    sendContactRequest={() => {
                                        setShowLoader(true);
        
                                        createConnectionRequest(
                                            contactUsername,
                                            username, 
                                            contact.email, 
                                            createConnectionRequestText(contact.firstName, myDetails.firstName + " " + myDetails.lastName, username)
                                        );
                                        goToAddnewcontactScreen(history);
                                    }}
                                    acceptContactRequest={() => {
                                        setShowLoader(true);
        
                                        acceptConnectionRequest(
                                            contactUsername,
                                            username,
                                            contact.email,
                                            createConnectionAcceptedText(contact.firstName, myDetails.firstName + " " + myDetails.lastName)
                                        );
                                        goToContactsScreen(history);
                                    }}
                                    declineConnectionRequest={() => {
                                        setShowLoader(true);
        
                                        declineConnectionRequest(
                                            contactUsername,
                                            username
                                        );
                                        goToContactsScreen(history);
                                    }}
                                    contactid={contactUsername}
                                />

                            </ContactDetails>
                        </div>


                    </div>
                }
            </div>
        </div>
    );
}