import React from 'react';

// import LandingPage from '../components/LandingPage';
// import queryString from 'query-string';

export default function AboutScreen({location}) {

  return(
    <div style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        padding: 100,
        paddingTop: 100
    }}>
        <div style={{
            maxWidth: 800,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
        }}>
            <h4 style={{color: '#42b3f5', fontWeight: 'normal'}}>About buildingonline.co.uk</h4>
            <h6 style={{color: '#42b3f5', marginBottom: 30}}>Connecting Construction | Building Relationships</h6>
            <p>Welcome to <span style={{color: '#42b3f5'}}>buildingonline.co.uk</span>, the business network specifically created to bring together the whole of the UK construction and built environment community in a fully-inclusive and collaborative way.</p>
            <p><span style={{color: '#42b3f5'}}>buildingonline.co.uk</span> has been created for all, whether trades-based, technical or professional, providing the ideal platform to share experiences and knowledge, find and create opportunities, collaborate and communicate, and ultimately, work better together.</p>
            
            <h5 style={{color: '#42b3f5', marginBottom: 30, marginTop: 60}}>Key Features & Benefits</h5>
            
            <p>Raise your profile</p>
            <p>Build your network</p>
            <p>Find work opportunities</p>
            <p>Construct your team</p>
            <p>Communicate with teammates</p>
            <p>Get help and advice</p>
            <p>Expand your knowledge</p>
            <p>Receive great offers</p>
            <p>Regular new features!</p>
        </div>
    </div>
  );
}