import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ContactsFilter({updateFilter}) {
  return (
    <Form.Group controlId="namePrefix">
      <Form.Control 
        as="select" 
        data-testid="inputNamePrefix" 
        required
        // defaultValue={defaultValue}
        // key={defaultValue} // https://github.com/facebook/react/issues/4101
        onChange={(event) => updateFilter(event.target.value)}
      >
        <option value='All Statuses'>All Contacts</option>
        <option value='Available Immediately'>Available Immediately</option>
        <option value='Available Soon'>Available Soon</option>
        <option value='Not Available'>Not Available</option>
        <option value='Not Looking'>Not Looking</option>
      </Form.Control>
    </Form.Group>
  );
}

ContactsFilter.propTypes = {
  updateFilter: PropTypes.func.isRequired
};