import React from 'react';
import MenuLink from '../Link';
import ComingSoonTeamsButton from '../ComingSoonTeamsButton';
import SignOutButton from '../SignOutButton';
import InviteButton from '../InviteButton';
import { Link } from "react-router-dom";

export default function MenuLinks({username}) {

    const links =
        [
            {title:'Home', pathname:"home"},
            {title:'Profile', pathname:"profile"},
            {title:'Availability', pathname:"status"},
            {title:'Contacts', pathname:"contacts"},
            {title:'Messaging', pathname:"inbox"},
            // {title:'TEAMS'},
            // {title:'JOBS'},
            // {title:'LEARN'}
        ];

    return (
        <div style={{display: 'flex', justifyContent:'center', flexDirection:'column', alignItems: 'center' }}>
                {links.map((link, index) => {
                    return (
                        <div style={{ marginBottom: 40 }} key={index}>
                            <Link to={{pathname: link.pathname}} style={{ textDecoration: 'none', display: 'flex' }}>                    
                                <MenuLink title={link.title}/>
                            </Link>
                        </div>
                    );
                })}

                <div style={{marginBottom: 40}}>
                    <InviteButton username={username}/>
                </div>

                <div style={{marginBottom: 40}}>
                    <ComingSoonTeamsButton/>
                </div>
                
                <Link to={{pathname: "/"}} style={{ textDecoration: 'none' }}>
                    <SignOutButton/> 
                </Link>
        </div>
    );
}