import { API, graphqlOperation } from 'aws-amplify';
import { getUser } from '../graphql/queries';

function doesUserExist(user){
    if(user === null) return false;
    if(user.firstName === null) return false;
    return true;
}

export default async function getUserDetails(username){
    const user = (await API.graphql(graphqlOperation(getUser, {id: username}))).data.getUser;
    var userCopy = Object.assign({}, user);
    userCopy.exists = doesUserExist(user);
    return userCopy;
}
