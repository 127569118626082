import getFormattedDate from '../lib/getFormattedDate';
import getActualAvailability from '../lib/getActualAvailability';

export default function filterConnections(connections, statusFilter, searchInput, occupationFilter){
    
    function checkStatus(connection){
        const {availability, availabilityDate} = connection.user;
        const actualAvailability = getActualAvailability(availability, availabilityDate, getFormattedDate());
        return statusFilter === "All Statuses" ? true : actualAvailability === statusFilter;
    }

    function checkName(connection){
        return connection.user.fullNameLowerCase.includes(searchInput);
    }

    function checkOccupation(connection){
        return occupationFilter === null ? true : connection.user.occupation.toLowerCase().includes(occupationFilter.toLowerCase());
    }


    return connections.filter(checkStatus).filter(checkName).filter(checkOccupation);
}