import React from 'react';

import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import PWAPrompt from 'react-ios-pwa-prompt';

import hoistNonReactStatics from 'hoist-non-react-statics';

import LandingPage from './screens/LandingPage';
import Menu from './screens/Menu';
import Home from './screens/Home';
import Profile from './screens/Profile';
import ProfileNonEditable from './screens/ProfileNonEditable';
import Contacts from './screens/Contacts';
import WorkStatus from './screens/WorkStatus';
import Contact from './screens/Contact';
import Chat from './screens/Chat';
import AddNewContact from './screens/AddNewContact';
import Inbox from './screens/Inbox';
import About from './screens/About';
import ContactUs from './screens/ContactUs';

// import Teams from './screens/Teams';
// import AddTeam from './screens/AddTeam';
// import Team from './screens/Team';
// import Members from './screens/Members';

Amplify.configure(awsmobile);

const signUpConfig = {
    defaultCountryCode: '44',
    // header: 'Create a new account (Password policy: Use 8 or more characters with a mix of letters, numbers & symbols)',
    hiddenDefaults: ['password'],
    signUpFields: [{
        label: 'Password (8 or more characters with a mix of letters, numbers & symbols)',
        key: 'password',
        required: true,
        placeholder: 'Password',
        displayOrder: 2,
        type: 'password'
      },
      {
        label: 'Email (where your sign up verification code will be sent)',
        key: 'email',
        required: true,
        displayOrder: 4,
        type: 'string',
        placeholder: 'Email',
      }      
    ]
};

// https://github.com/aws-amplify/amplify-js/issues/3578
const withSignUpAuthState = (ChildComponent) => {
  class Enhance extends React.Component {
    render() {
      return (
        <ChildComponent {...this.props} authState='signUp' />
      );
    }
  }
  hoistNonReactStatics(Enhance, ChildComponent);
  return Enhance;
};

function AuthenticatedRoute({component, path}){
  return (
    <Route path={path} component={withAuthenticator(component, {signUpConfig: signUpConfig})} />
  );
}

const authenticatedRoutes = [
  {component: Home, path: '/home'},
  {component: Menu, path: '/menu'},
  {component: Profile, path: '/profile'},
  {component: ProfileNonEditable, path: '/profile_non_editable'},
  {component: Contacts, path: '/contacts'},
  {component: WorkStatus, path: '/status'},
  {component: Contact, path: '/contact'},
  {component: Chat, path: '/chat'},
  {component: AddNewContact, path: '/addnewcontact'},
  {component: Inbox, path: '/inbox'},
];

export default function App() {

  return (
    <Router>
      <ScrollToTop>
          <PWAPrompt />
          
          <Route exact path="/" component={LandingPage} />
          <Route path="/about" component={About} />
          <Route path="/contact_us" component={ContactUs} />

          {authenticatedRoutes.map((x, index) => <AuthenticatedRoute key={index} component={x.component} path={x.path}/>)}
          <Route path={'/home_join_us'} component={withSignUpAuthState(withAuthenticator(Home, {signUpConfig: signUpConfig}))} />

      </ScrollToTop>
    </Router>
  );
}
