/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser1 = `query GetUser($id: ID! $nextToken: String) {
  getUser(id: $id) {
    id
    connections(nextToken: $nextToken) {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        connectionUserID
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    email
    chatAuthToken
    feedAuthToken
    firstName
    lastName
    fullNameLowerCase
    namePrefix
    postcodeRegion
    occupation
    company
    workType
    availability
    availabilityDate
    postToNetwork
    furtherInfo
    connections {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        connectionUserID
        createdAt
        updatedAt
      }
      nextToken
    }
    requests {
      items {
        id
        user {
          id
          email
          chatAuthToken
          feedAuthToken
          firstName
          lastName
          fullNameLowerCase
          namePrefix
          postcodeRegion
          occupation
          company
          workType
          availability
          availabilityDate
          postToNetwork
          furtherInfo
          createdAt
          updatedAt
        }
        requestUserID
        status
        createdAt
        updatedAt
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getRequest = `query GetRequest($id: ID!) {
  getRequest(id: $id) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    requestUserID
    status
    createdAt
    updatedAt
  }
}
`;
export const listRequests = `query ListRequests(
  $filter: ModelRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        email
        chatAuthToken
        feedAuthToken
        firstName
        lastName
        fullNameLowerCase
        namePrefix
        postcodeRegion
        occupation
        company
        workType
        availability
        availabilityDate
        postToNetwork
        furtherInfo
        connections {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      requestUserID
      status
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
export const getConnection = `query GetConnection($id: ID!) {
  getConnection(id: $id) {
    id
    user {
      id
      email
      chatAuthToken
      feedAuthToken
      firstName
      lastName
      fullNameLowerCase
      namePrefix
      postcodeRegion
      occupation
      company
      workType
      availability
      availabilityDate
      postToNetwork
      furtherInfo
      connections {
        items {
          id
          connectionUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          requestUserID
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
    connectionUserID
    createdAt
    updatedAt
  }
}
`;
export const listConnections = `query ListConnections(
  $filter: ModelConnectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        email
        chatAuthToken
        feedAuthToken
        firstName
        lastName
        fullNameLowerCase
        namePrefix
        postcodeRegion
        occupation
        company
        workType
        availability
        availabilityDate
        postToNetwork
        furtherInfo
        connections {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      connectionUserID
      createdAt
      updatedAt
    }
    nextToken
  }
}
`;
