import React from 'react';

import { FormControl, Button, Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

export default function SearchInput({findUsersEvent}) {

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { search } = event.target.elements;
        findUsersEvent(search.value.toLowerCase());
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
            <Form
                noValidate 
                data-testid="form"
                onSubmit={handleSubmit}
                style={{width: "100%", display: 'flex', justifyContent: 'center'}}
            >
                <Form.Group style={{width: '90%', display: 'flex', justifyContent: 'center'}} controlId="search">
                    <FormControl
                        placeholder="Type here..."
                        data-testid='searchInput'
                        required
                        type="text"
                    />
                    <Button variant="outline-primary" style={{borderRadius: '5px'}} type="submit">
                        <FaSearch size={20}/>
                    </Button>
                </Form.Group>
            </Form>
        </div>
    );
}

SearchInput.defaultProps = {
  findUsersEvent: (text) => console.log('no submitEvent function for SearchInput: ', text)
};
