import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import OccupationInput from '../../lib/OccupationInput';

// Required for React-styleguidist
import 'bootstrap/dist/css/bootstrap.css';

function TextInput({placeholder, label, controlId, defaultValue}){
  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control 
        required
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">Please add.</Form.Control.Feedback>
    </Form.Group>
  );
}

function NamePrefixDropdownInput({defaultValue}){
  return (
    <Form.Group controlId="namePrefix">
      <Form.Label>Name Prefix</Form.Label>
      <Form.Control 
        as="select" 
        required
        defaultValue={defaultValue}
        key={defaultValue} // https://github.com/facebook/react/issues/4101
      >
        <option value='Mr'>Mr</option>
        <option value='Mrs'>Mrs</option>
        <option value='Miss'>Miss</option>
        <option value='Ms'>Ms</option>
        <option value='Dr'>Dr</option>
        <option value='Prof'>Prof</option>
      </Form.Control>
    </Form.Group>
  );
}

function OccupationInputWithValidation({currentOccupation, setCurrentOccupation, showBootstrapOccupationInput, setShowBootstrapOccupationInput}){
  return (
    <Form.Group controlId="occupation">
      <Form.Label>Occupation</Form.Label>
  
      { showBootstrapOccupationInput ?
        <Form.Control 
          required
          type="text"
          placeholder="Start typing occupation"
          defaultValue={currentOccupation}
          onClick={() => setShowBootstrapOccupationInput(false)}
        />
      :
        <div style={{marginBottom: 10}}>
          <OccupationInput
            currentOccupation={currentOccupation}
            setCurrentOccupation={setCurrentOccupation}
          />
        </div>
      }
  
      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">Please add.</Form.Control.Feedback>
    </Form.Group>
  );
}


export default function ProfileDetails({userDetails, submitEvent}) {

  const { firstName, lastName, namePrefix, postcodeRegion, occupation, company, furtherInfo } = userDetails;

  const [currentOccupation, setCurrentOccupation] = useState(occupation);
  const [showBootstrapOccupationInput, setShowBootstrapOccupationInput] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    setShowBootstrapOccupationInput(true);

    if (form.checkValidity() === true) {

      const { firstName, lastName, namePrefix, postcodeRegion, company, furtherInfo } = event.target.elements;

      const newUserDetails = {
        firstName: firstName.value, 
        lastName: lastName.value, 
        namePrefix: namePrefix.value, 
        postcodeRegion: postcodeRegion.value, 
        occupation: currentOccupation || " ", 
        company: company.value, 
        furtherInfo: furtherInfo.value
      };

      submitEvent(newUserDetails);
 
    }

    setValidated(true);
  };

  return (
    <div style={{display: 'flex', justifyContent:'center', paddingTop: 60, paddingBottom: 60}}>
      <Form 
        noValidate // disable HTML5 validation
        validated={validated}
        onSubmit={handleSubmit}
        style={{maxWidth: 450, width: "100%"}}
      >
        <NamePrefixDropdownInput defaultValue={namePrefix}/>

        <TextInput
          placeholder="Enter first name"
          label="First name"
          controlId="firstName"
          defaultValue={firstName}
        />

        <TextInput
          placeholder="Enter last name"
          label="Last name"
          controlId="lastName"
          defaultValue={lastName}
        />
  
        <TextInput
          placeholder="Enter town/city"
          label="Town / City"
          controlId="postcodeRegion"
          defaultValue={postcodeRegion}
        />

        <OccupationInputWithValidation
          currentOccupation={currentOccupation}
          setCurrentOccupation={setCurrentOccupation}
          showBootstrapOccupationInput={showBootstrapOccupationInput}
          setShowBootstrapOccupationInput={setShowBootstrapOccupationInput}
        />

        <TextInput
          placeholder="Enter company"
          testid="inputCompany"
          label="Company"
          controlId="company"
          defaultValue={company}
        />

        <Form.Group controlId="furtherInfo">
          <Form.Label>Additional information about me</Form.Label>
          <Form.Control
            as="textarea"
            rows="6"
            placeholder='e.g. what you do, key skills, education and qualifications...'
            defaultValue={furtherInfo}
          />
        </Form.Group>

        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <Button variant="primary" type="submit" block>
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

ProfileDetails.defaultProps = {
  userDetails: {
    firstName: null,
    lastName: null,
    namePrefix: null,
    postcodeRegion: null,
    occupation: null,
    company: null
  },
  submitEvent: (userDetails) => console.log('no submitEvent function for ProfileDetails: ', JSON.stringify(userDetails))
};
