import React, {useEffect, useState} from 'react';

import Navbar from '../components/lib/Navbar';
import ConfirmDeleteModal from '../components/Home/ConfirmDelete';
import postStyle from '../functions/Home/postStyle';
import getUsername from '../impureFunctions/getUsername';
import getUserDetails from '../impureFunctions/getUserDetails';
import createNewUser from '../impureFunctions/createNewUser';
import removePost from '../impureFunctions/removePost';
import goToCreateProfile from '../impureFunctions/goToCreateProfile';
import goToMenuScreen from '../impureFunctions/goToMenuScreen';
import DeletePostButton from '../components/Home/DeletePostButton';
import addEmailToUserInfo from '../impureFunctions/addEmailToUserInfo';
import queryString from 'query-string';
import ContactPicture from '../impureComponents/ContactPicture';
import moment from 'moment';
import stream from 'getstream';

// import { Analytics } from 'aws-amplify';


import createConnectionRequest from '../impureFunctions/createConnectionRequest';
import createConnectionRequestText from '../functions/Contact/createConnectionRequestText';

import { StreamApp, FlatFeed, LikeButton, Activity, CommentList, CommentField, StatusUpdateForm } from 'react-activity-feed';
// import { StreamApp, NotificationDropdown, FlatFeed, LikeButton, Activity, CommentList, CommentField, StatusUpdateForm } from 'react-activity-feed';
import 'react-activity-feed/dist/index.css';

export default function Home({history, location}) {

    const newLogin = queryString.parse(location.search).login === 'true';
    const invitedBy = queryString.parse(location.search).invite;

    const [feedAuthToken, setFeedAuthToken] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [userId, setUserId] = useState(null);
    
    const [id, setId] = useState(null);

    useEffect(() => {
      async function onScreenStartUp(){
        
        // Analytics.record({ name: 'userOnHomePage' });

        const userDetails = await getUsername(true);
        const {username, email} = userDetails;
        const user = await getUserDetails(username);

        if(user.exists){
            if(!user.email){
              await addEmailToUserInfo(userDetails);
            }

            if(!user.firstName){
              return goToCreateProfile(history);
            }
            
            setFeedAuthToken(user.feedAuthToken);
            setUserId((await stream.connect('ghh53eh4dzjg', user.feedAuthToken,  '57538')).userId);

            return;
        }

        await createNewUser(userDetails);
        
        if(invitedBy){
          const contact = await getUserDetails(invitedBy);
          await createConnectionRequest(
              username,
              invitedBy,
              email, 
              createConnectionRequestText(user.firstName, contact.firstName + " " + contact.lastName, invitedBy)
          ); 
        }        
        
        return goToCreateProfile(history);
      }
      onScreenStartUp();

      if(window.Tawk_API) window.Tawk_API.hideWidget();
      
    }, [history, invitedBy]);

// https://getstream.io/react-activity-feed/tutorial/

    return (
      <div style={{minHeight: '100vh', width: '100vw'}}>
      { newLogin ? <Navbar history={history} newLogin/> : <Navbar history={history}/> }
<p style={{fontSize: 'small'}}>version 1.8.5</p>

        <ConfirmDeleteModal
          show={confirmDelete}
          onHide={() => setConfirmDelete(false)}
          removePost={() => {
            removePost(id, feedAuthToken, userId);
            setConfirmDelete(false);
            return goToMenuScreen(history);
          }}
        />

        <div style={{minHeight: '100vh', width: '100vw', padding: 20, maxWidth: 800, margin: "0 auto"}}>
            {feedAuthToken && 

              <StreamApp
                apiKey="ghh53eh4dzjg"
                appId="57538"
                token={feedAuthToken}
              >
        {/*
                <NotificationDropdown feedGroup="user" notify />
        */}
                <StatusUpdateForm
                  feedGroup="user"
                />
                <FlatFeed
                    feedGroup="user"
                    options={ {reactions: { recent: true } } }
                    notify
                    Activity={(props) => {

                      const raw_post = props.activity.object;
                      props.activity.object = raw_post.replace("UPDATED WORK STATUS", "I've updated my availability").replace("Work Type", "Looking for");
                      
                      return <Activity {...props}
                        Header={() => {
                          const pic_id = props.activity.actor.id;
                          const name = props.activity.actor.data.name;
                        
                          return (
                            <div style={ postStyle(raw_post) }>

                              <div style={{display: 'flex'}}>
                                <div style={{ width: '50px', height: '50px',  marginRight: "10px"}}>
                                  <ContactPicture id={pic_id} />
                                </div> 
                                <p style={{padding: '8px 16px', fontWeight: 'bold'}}>{name}</p>
                              </div>

                              <p style={{color: 'grey'}}>{moment(props.activity.time).fromNow()}</p>
                            </div>
                          );
                        }}

// Content	={() => {
//   return (
//     <p>{}</p>
//   )
// }}

                        Footer={() => {
                          const {activity, onAddReaction} = props;
                          const authorId = activity.actor.id;
                          const postId = activity.id;
                          return (
                            <div style={{marginBottom: 60}}>
                              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <LikeButton {...props} />
                                <DeletePostButton
                                  authorId={authorId}
                                  username={userId}
                                  onClick={() => {
                                    setId(postId);
                                    setConfirmDelete(true);
                                  }}
                                />
                              </div>
                              <CommentField
                                activity={activity}
                                onAddReaction={onAddReaction} />
                              <CommentList activityId={postId} />
                            </div>
                          )}
                        }
                      />;
                    }}
                  />
              </StreamApp>
                
            }
        </div>
        
<div id="DIV_country"/>      
        
      </div>
    );
}