import React, {useState, useEffect} from 'react';

import {Form, Button} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import getDate from '../../functions/WorkStatus/getDate';
import dateString from '../../functions/WorkStatus/dateString';
import createFeedMessage from '../../functions/WorkStatus/createFeedMessage';

export default function WorkStatus({details, submitEvent}) {

  const { workType, availability, availabilityDate, postToNetwork } = details;

  const [date, setDate] = useState(new dateString(availabilityDate).toDate);
  const handleDateChange = (newDate) => setDate(newDate);

  const [showDatePicker, setShowDatePicker] = useState(availability === "Available Soon");
  
  useEffect(() => {
    setShowDatePicker(availability === "Available Soon");
    setDate(new dateString(availabilityDate).toDate);
  }, [availability, availabilityDate]);
  
  const handleNewAvailability = (text) => setShowDatePicker(text.target.value === "Available Soon");

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { workType, availability, postToNetwork, furtherInfo } = event.target.elements;

    const newWorkType = workType.value;
    const newAvailability = availability.value;
    const newAvailabilityDate = showDatePicker ? getDate(date) : null;
    const newPostToNetwork = postToNetwork.checked.toString();
    const newFurtherInfo = furtherInfo.value;

    const userDetails = {
      workType: newWorkType,
      availability: newAvailability, 
      availabilityDate: newAvailabilityDate, 
      postToNetwork: newPostToNetwork
    };

    submitEvent(userDetails, createFeedMessage(newWorkType, newAvailability, newAvailabilityDate, newFurtherInfo));
  };

  return (
    <div style={{display: 'flex', justifyContent:'center', paddingTop: 60, paddingBottom: 60}}>

      <Form noValidate 
        data-testid="form"
        onSubmit={handleSubmit}
        style={{maxWidth: 450, width: "100%"}}
      >
        <Form.Group controlId="workType">
          <Form.Label>What type of work are you looking for?</Form.Label>
          <Form.Control 
            as="select" 
            data-testid="inputWorkType" 
            required
            defaultValue={workType}
            key={workType} // https://github.com/facebook/react/issues/4101
          >
            <option value='Trades/Contracting'>Trades/Contracting</option>
            <option value='Freelance'>Freelance</option>
            <option value='Full Time Employment'>Full Time Employment</option>
            <option value='Part Time Employment'>Part Time Employment</option>
            <option value='Not Looking'>Not Looking</option>
          </Form.Control>
        </Form.Group>          

        <Form.Group controlId="availability">
          <Form.Label>When are you available?</Form.Label>
          <Form.Control
            as="select" 
            data-testid="inputAvailability" 
            required
            defaultValue={availability}
            key={availability} // https://github.com/facebook/react/issues/4101
            onChange={handleNewAvailability}
          >
            <option value='Available Immediately'>Available Immediately</option>
            <option value='Available Soon'>Available Soon</option>
            <option value='Not Available'>Not Available</option>
            <option value='Not Looking'>Not Looking</option>
          </Form.Control>
        </Form.Group>

        {showDatePicker  &&
          <Form.Group>
            <Form.Label>When are you available to start?</Form.Label>
            <div>
              <DatePicker
                id="inputAvailabilityDate"
                selected={date}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                minDate={new Date()}
              />
            </div>
          </Form.Group> 
        }

        <Form.Group controlId="postToNetwork">
          <Form.Check 
            type='checkbox'
            data-testid="checkboxPostToNetwork" 
            label='Post to network'
            defaultChecked={postToNetwork === "true"}
            key={postToNetwork} // https://github.com/facebook/react/issues/4101
          />
          <Form.Text className="text-muted">
            Tick to post your availability updates on the news feed of your connections.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="furtherInfo">
          <Form.Label>More info:</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            data-testid="inputFurtherInfo" 
            placeholder='Type here...'
          />
        </Form.Group>

        <div style={{ textAlign: 'center', marginTop: 40 }}>
          <Button variant="primary" type="submit" block>
            Update
          </Button>
        </div>
      </Form>

    </div>
  );
}

WorkStatus.defaultProps = {
  details: {
    workType: null,
    availability: null,
    availabilityDate: null,
    postToNetwork: null
  },
  submitEvent: (userDetails) => console.log('no submitEvent function for ProfileDetails: ', JSON.stringify(userDetails))
};
