import React, {useState, useEffect} from 'react';

import UserList from '../components/Contacts/List';
import SearchInput from '../components/lib/SearchInput';
import ContactsFilter from '../components/Contacts/Filter';
import Navbar from '../components/lib/Navbar';
import goToAddnewcontactScreen from '../impureFunctions/goToAddnewcontactScreen';
import { Button } from 'react-bootstrap';
import getConnectionRequests from '../impureFunctions/getConnectionRequests';
import getConnections from '../impureFunctions/getConnections';
import filterConnections from '../functions/Contacts/filterConnections';
import OccupationInput from '../components/lib/OccupationInput';


// I THINK I SHOULD BE ABLE TO USE CUSTOM HOOKS IN THIS COMPONENT (fetchRequests AND fetchContacts)

export default function Contacts({history}) {

    const [nextTokenRequests, setNextTokenRequests] = useState(null);
    const [requests, setRequests] = useState([]);
    const [hasMoreRequests, setHasMoreRequests] = useState(false);

    const [nextToken, setNextToken] = useState(null);
    const [connections, setConnections] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [statusFilter, setStatusFilter] = useState("All Statuses");
    const filterEvent = (text) => {
        setConnections([]);
        setNextToken(null);
        setStatusFilter(text);
    };

    const [occupationFilter, setOccupationFilter] = useState(null);
    const occupationFilterEvent = (text) => {
        setConnections([]);
        setNextToken(null);
        setOccupationFilter(text);
    };
    
    const [searchInput, setSearchInput] = useState(" ");
    const findUsersEvent = (text) => {
        setConnections([]);
        setNextToken(null);
        setSearchInput(text);
    };
    
    async function fetchRequests(nextToken){
        try{
            setHasMoreRequests(false);
            const { nextToken, newRequests, hasMore } = await getConnectionRequests();
            setRequests(currentRequests => [...currentRequests, ...newRequests]);
            setHasMoreRequests(hasMore);
            setNextTokenRequests(nextToken);
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchRequests(null);
        if(window.Tawk_API) window.Tawk_API.hideWidget();
    }, []);

    async function fetchContacts(nextToken, isLoading, statusFilter, occupationFilter, searchInput){
        try{
            if(isLoading) return;
            setIsLoading(true);  
            
            setHasMore(false);
            const connectionsResponse = await getConnections(nextToken);
            setConnections(currentData => [...currentData, ...filterConnections(connectionsResponse.connections, statusFilter, searchInput, occupationFilter)]);
            setHasMore(connectionsResponse.hasMore);
            setNextToken(connectionsResponse.nextToken);
            setIsLoading(false);
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchContacts(null, false, statusFilter, occupationFilter, searchInput);
    }, [statusFilter, occupationFilter, searchInput]);

    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
        
            <Navbar history={history}/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div style={{minHeight: '100vh', width: '90vw', paddingTop: 30, maxWidth: 800, margin: "0 auto"}}>

                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
                        <Button variant="primary" onClick={() => goToAddnewcontactScreen(history)}>Search / Add New</Button>
                    </div>
                
                    <SearchInput findUsersEvent={findUsersEvent}/>
                    
                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <ContactsFilter updateFilter={filterEvent}/>
                        <OccupationInput setCurrentOccupation={occupationFilterEvent}/>
                    </div>
                    
                    <UserList users={requests} loadFunc={() => fetchRequests(nextTokenRequests)} hasMore={hasMoreRequests} />
                    <UserList users={connections} loadFunc={() => fetchContacts(nextToken, isLoading, statusFilter, occupationFilter, searchInput)} hasMore={hasMore}/>
                </div>
            </div>
        </div>
    );
}