import React from 'react';
import { Button } from 'react-bootstrap';

export default function ComingSoonTeamsButton() {
    return (
        <div style={{ width: '18rem' }}>
            <Button
                variant="light"
                active
                block
                style={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent:'space-between', 
                    alignItems:'center',
                    borderWeight: 1,
                    borderColor: 'black'
                }}
            >
                <h5 style={{color: 'white', fontFamily: "Verdana"}}>New Features</h5>
                <p style={{color: 'white', fontStyle: 'italic'}}>
                    coming soon!
                </p>
            </Button>
        </div>   

    );
}