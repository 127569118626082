// https://github.com/talkjs/talkjs-examples/tree/master/react/basic-example

import React, { useEffect, useRef } from 'react';
import Talk from 'talkjs';
import Navbar from '../components/lib/Navbar';
import getUsername from '../impureFunctions/getUsername';
import { getUser } from '../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify';

async function getName(username){
    const user = await (await API.graphql(graphqlOperation(getUser, {id: username}))).data.getUser;
    const {email, firstName, lastName, chatAuthToken} = user;
    return {email: email, name: `${firstName} ${lastName}`, chatAuthToken: chatAuthToken};
}

export default function Main({history, location}) {
    const messagesEndRef = useRef();

    useEffect(() => {
        async function startChat(){

            const username = await getUsername();
            // const photoUrl = await Storage.get(username);
            const photoUrl = await Storage.get("profile-pics/" + username + ".jpeg");

            const myDetails = await getName(username);

            var inbox;
            Talk.ready.then(() => {
                const me = new Talk.User({
                    id: username,
                    name: myDetails.name,
                    email: myDetails.email,
                    photoUrl: photoUrl,
                    role: "user"
                    // welcomeMessage: "Hey there! How are you? :-)"
                });

                if (!window.talkSession) {
                    window.talkSession = new Talk.Session({
                        appId: process.env.REACT_APP_TALKJS_APPID || 't7BeH1Yx',
                        me: me,
                        signature: myDetails.chatAuthToken
                    });
                }

                let chatView = messagesEndRef.current;
            
                inbox = window.talkSession.createInbox();
                // inbox.on("conversationSelected", (event) => {
                //     if(event.conversation){
                //         history.push({pathname: '/chat', search: `?conversationId=${event.conversation.id}&contactid=${event.other.id}`});
                //     }
                // });
                inbox.mount(chatView);

            })
            .catch(e => console.error(e));

            // Specify how to clean up after this effect:
            return function cleanup() {
                inbox.destroy();
            };
          
        }
        startChat();
        
        if(window.Tawk_API) window.Tawk_API.hideWidget();
    }, [history]);

    return (
        <div style={{minHeight: '100vh', width: '100vw', paddingBottom: 50}}>
            <Navbar history={history}/>
            <div style={{height: '90vh', minHeight: '90vh', width: '100vw', display:'flex', justifyContent: 'center', padding: 20}}>
                <div ref={messagesEndRef} style={{width: '90vw', maxWidth: '800px', height: '90vh', minHeight: '500px'}}/>
            </div>
        </div>
    );

}