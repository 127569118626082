// https://react-select.com/creatable
// https://www.polvara.me/posts/testing-a-custom-select-with-react-testing-library/

import React from 'react';
import CreatableSelect from 'react-select/creatable';
import occupations from './occupations';
import PropTypes from 'prop-types';

function createOptions(occupations, currentOccupation){
    return occupations.map(x => {
        return {
            value: x.toLowerCase,
            label: x
        };
    });
}

export default function OccupationInput({currentOccupation, setCurrentOccupation}){
    return(
        <CreatableSelect
            options={createOptions(occupations)}
            defaultInputValue={currentOccupation || ""}
            onChange={(event) => {
                if(event === null){
                    setCurrentOccupation("");
                    return;
                }
                setCurrentOccupation(event.label)}
            }
            placeholder="Start typing occupation..."
            isClearable={true}
        />
    );
}

OccupationInput.propTypes = {
//   currentOccupation: PropTypes.string.isRequired,
  setCurrentOccupation: PropTypes.func.isRequired
};