import { API, graphqlOperation } from 'aws-amplify';
import { deleteRequest } from '../graphql/mutations';

export default async function declineConnectionRequest(contactUsername, username){
    try {
        await API.graphql(graphqlOperation(deleteRequest, {input: {id: `${username}-${contactUsername}`}}));
        await API.graphql(graphqlOperation(deleteRequest, {input: {id: `${contactUsername}-${username}`}}));
    } catch(error){
        console.log(error);
    }
}