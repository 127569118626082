import React, {useState, useEffect} from 'react';

// import { S3Image } from 'aws-amplify-react';

import { FaUser} from 'react-icons/fa';

import { Storage } from 'aws-amplify';
import {Image} from 'react-bootstrap';

export default function ContactPicture({id, border}) {

    const [url, setUrl] = useState(null);
    const [error, setError] = useState(false);
    
    useEffect(() => {
        async function getUrl(){
            const url = await Storage.get("profile-pics/" + id + ".jpeg");
            setUrl(url);
            setError(false);
        }
        getUrl();
    }, [id]);

    const image_style = {height: "100%", width: "100%", objectFit: 'cover'};
    if(border) image_style.border = `5px solid ${border}`;

    if(error) return <FaUser size="100%" style={{color: 'lightgrey', borderRadius: "50%"}}/>;

    return (

        url ? <Image src={url} fluid roundedCircle style={image_style} onError={() => setError(true)}/> : <div></div>

        // <S3Image 
        //     // theme={{ photoImg: { width: '300px', height: '200px',  marginTop: 20 } }}
        //     theme={{ photoImg: {width: '100%', maxWidth: '300px', height:'auto'} }}              
        //     imgKey={"profile-pics/" + id + ".jpeg"}
        //     // onError={() => setShow(false)}
        //     // style={{imageOrientation: "from-image"}}
        // />

    );

}
