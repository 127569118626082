import React, {useState, useEffect} from 'react';

import AddContactUserList from '../components/AddContact/UserList';
import SearchInput from '../components/lib/SearchInput';
import Navbar from '../components/lib/Navbar';
import { API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../graphql/queries';
import getUsername from '../impureFunctions/getUsername';

export default function AddNewContact({history}) {

    const [nextToken, setNextToken] = useState(null);
    const [users, setUsers] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [searchInput, setSearchInput] = useState("");
    const findUsersEvent = (text) => setSearchInput(text);

    async function fetchData(nextToken, searchInput, users, isLoading){
        
        if(isLoading) return;

        setIsLoading(true);
        setHasMore(false);
        const username = await getUsername();

        const filter = (searchInput === "") ? {id: {ne: username}} : {
            id: {ne: username},
            fullNameLowerCase: { contains: searchInput }
        };
        
        const result = (await API.graphql(graphqlOperation(listUsers, {
            nextToken: nextToken,
            filter: filter
        }))).data.listUsers;
        
        setUsers([...users, ...result.items.filter(x => x.firstName != null)]);
        (result.nextToken === null) ? setHasMore(false) : setHasMore(true);
        setNextToken(result.nextToken);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData(null, searchInput, [], false);
        if(window.Tawk_API) window.Tawk_API.hideWidget();
    }, [searchInput]);


    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
            <Navbar history={history}/>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 40}}>
                <div style={{minHeight: '100vh', width: '90vw', maxWidth: 800, margin: "0 auto"}}>
                    <SearchInput findUsersEvent={findUsersEvent}/>
                    <AddContactUserList
                        users={users}
                        loadFunc={() => fetchData(nextToken, searchInput, users, isLoading)}
                        hasMore={hasMore}
                    />
                </div>
            </div>
        </div>
    );
}