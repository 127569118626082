import React from 'react';
import { FaHome, FaUserCircle, FaAddressBook, FaInbox, FaTrafficLight} from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function MenuLink({title}) {

    function selectIcon(title) {
      switch(title) {
        case 'Home': return <FaHome size={20} style={{color: 'grey'}}/>;
        case 'Availability': return <FaTrafficLight size={20} style={{color: 'grey'}}/>;
        case 'Profile': return <FaUserCircle size={20} style={{color: 'grey'}}/>;
        case 'Contacts': return <FaAddressBook size={20} style={{color: 'grey'}}/>;
        case 'Messaging': return <FaInbox size={20} style={{color: 'grey'}}/>;
        // case 'TEAMS': return <FaUserFriends size={20}/>;
        // case 'JOBS': return <FaIdCard size={20}/>;
        // case 'LEARN': return <FaGraduationCap size={20}/>;
        default: return;
      }
    }
    
    return (
            <div style={{width: '18rem'}}>        
                <Button
                    variant="light"
                    block
                    style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems:'center'}}
                >
                    <h5 style={{color: '#42aaf5', fontFamily: "Verdana"}}>
                        {title}
                    </h5>
                    {selectIcon(title)}
                </Button>
            </div>
    );
}

MenuLink.propTypes = {
  title: PropTypes.string.isRequired
};