/* global _iub */

import React, {useState, useEffect} from 'react';

import ProfilePicture from '../impureComponents/ProfilePicture';
import ProfileDetails from '../components/Profile/Details';
import Navbar from '../components/lib/Navbar';
import queryString from 'query-string';
import getUsername from '../impureFunctions/getUsername';
import getUserDetails from '../impureFunctions/getUserDetails';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import updateUserDetails from '../impureFunctions/updateUserDetails';
import goToScreen from '../impureFunctions/goToScreen';
import { DropdownButton, Dropdown, Modal, Button } from 'react-bootstrap';

// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

export default function Profile({history, location}) {

    const newuser = queryString.parse(location.search).newuser;

    const [username, setUsername] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [showLoader, setShowLoader] = useState(false);

    const [show, setShow] = useState(true);

    const handleClose = () => {

        _iub.cons_instructions.push(["submit",
          {
            consent: {
              subject: {
                id: username,
                email: username // the email seems to be used Iubenda as the subject, so I want to use the username
              },
              preferences: {
                terms: true
              },
              legal_notices: [{identifier: "privacy_policy"}, {identifier: "cookie_policy"}, {identifier: "terms"}],
              proofs: [
                {
                  content: " ",
                  form: " "
                }
              ]
            }
          },
          {
            success: function(response) {
            //   console.log(response);
            },
            error: function(response) {
            //   console.log(response);
            }
          }
        ]);

        setShow(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            const username = await getUsername();
            setUsername(username);
            setUserDetails(await getUserDetails(username));
        };
        
        fetchData();
        if(window.Tawk_API) window.Tawk_API.hideWidget();

// function geoSuccess(position){
//   console.log(position.coords)
// }
// function geoError(){
//   console.log('no permission')
// }
// navigator.geolocation.getCurrentPosition(geoSuccess, geoError);        

    }, []);

    return (
        showLoader ? 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '90vw'}}>
            <Loader
             type="Puff"
             color="#00BFFF"
             height={150}
             width={150}
            />
        </div>
        :
        <div style={{minHeight: '100vh', width: '100vw'}}>
            {newuser === "true" ? <Navbar history={history} hideButtons/> : <Navbar history={history}/>}

            {newuser === "true" &&
                <Modal show={show} centered>
                    <Modal.Header>
                        <Modal.Title>Legal stuff</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>By clicking Agree, you have read and agree to Building Online Limited’s <a href="https://www.iubenda.com/terms-and-conditions/28069395" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>, <a href="https://www.iubenda.com/privacy-policy/28069395/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a> and <a href="https://www.iubenda.com/privacy-policy/28069395" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>Agree</Button>
                    </Modal.Footer>
                </Modal>
            }
      
{/*
    <GooglePlacesAutocomplete
    //   onSelect={async x => console.log(getLatLng(await x))}
    //   onSelect={x => console.log(x.description)}
      onSelect={x => console.log(x)}
    />
*/}

            <div>

                {newuser !== "true" &&
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                        <DropdownButton id="dropdown-basic-button" title="Actions">
                          <Dropdown.Item onClick={goToScreen(history, 'profile_non_editable')}>View your current profile</Dropdown.Item>
                          <Dropdown.Item onClick={goToScreen(history, 'status')}>Update your Availability</Dropdown.Item>
                        </DropdownButton>
                    </div>
                }


    
                { userDetails &&
                    <div style={{padding: 20, marginTop: 40}}>
                        <ProfilePicture username={username} />
                        <ProfileDetails 
                            userDetails={userDetails}
                            submitEvent={async (eventUserDetails) => {
                                setShowLoader(true);
                                await updateUserDetails(eventUserDetails, username, setShowLoader, userDetails.feedAuthToken, newuser, userDetails.chatAuthToken).catch(() => {
                                    // log this error
                                    console.log('error in updating user details');
                                });
                                setShowLoader(false);
                                return (goToScreen(history, 'home'))();
                            }}
                        />
                    </div>
                }
            
            </div>
        
        </div>
    );
}
