import React from 'react';

import { Jumbotron, Button, Navbar, Nav, NavDropdown, DropdownItem } from 'react-bootstrap';
import background from '../../images/landing_page_background.png';
import { Link } from "react-router-dom";
import BuildingOnlineLogo from '../../images/BOL_navbar_logo_cropped.jpg';

function Feature({text}){
  return(
      <div style={{ padding: 10, maxWidth: 600, width: '90%' }}>
        <h5>{text}</h5>
      </div>
  );
}

export default function LandingPage({invitedBy}){

  return(
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${background})`, backgroundSize: 'contain'}}>


      <div style={{
        padding: 10,
        borderRadius: "5px",
        width: '70%',
        marginTop: 20,
        textAlign: 'center',
        backgroundColor: 'white',
        color: '#42b3f5',
        opacity: 0.8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>

          <div style={{maxWidth: 600, display: 'flex', justifyContent: 'space-around', width: "100%"}}>
            <Link to={{pathname: "/home", search: invitedBy ? `?login=true&invite=${invitedBy}` : `?login=true`}} style={{textDecoration: 'none', width: "45%", maxWidth: 200}}>
                <Button variant="outline-primary" size="lg" block style={{fontSize: "medium"}}>
                    Log in
                </Button>
            </Link>
            <Link to={{pathname: "/home_join_us", search: invitedBy ? `?login=true&invite=${invitedBy}` : `?login=true`}} style={{textDecoration: 'none', width: "45%", maxWidth: 200}}>
                <Button variant="outline-secondary" size="lg" block style={{fontSize: "medium"}}>
                    Join us
                </Button>
            </Link>
          </div>
          
      </div>

      <Jumbotron style={{
        width: '70%',
        marginTop: 20,
        textAlign: 'center',
        backgroundColor: 'white',
        color: '#42b3f5',
        opacity: 0.8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>

          <div style={{marginBottom: 30, maxWidth: 600}}>
            <img src={BuildingOnlineLogo} alt="logo" height="auto" width="60%"/>
          </div>
          
          <h4 style={{marginBottom: 40}}>
            Connecting Construction | Building Relationships
          </h4>

          <h5 style={{marginBottom: 40}}>
            The business network for the UK construction and built environment community
          </h5>
  
          <Feature text="Raise your profile"/>
          <Feature text="Build your network"/>
          <Feature text="Find work opportunities"/>
          <Feature text="Construct your team"/>
          <Feature text="Communicate with teammates"/>
          <Feature text="Get help and advice"/>
          <Feature text="Expand your knowledge"/>

      </Jumbotron>

      <Navbar bg="light" style={{width: "100vw", maxWidth: "100vw", display: 'flex', flexDirection: 'column' }}>
        <Navbar.Brand style={{fontSize: 'small', color: '#42b3f5'}}>
            Building Online Limited &copy; 2020
        </Navbar.Brand>

        <Nav style={{width: "100%"}} className="justify-content-center">
          <NavDropdown title="Links" drop="up">

            <DropdownItem href="/about">About</DropdownItem>
            
            <DropdownItem href="https://www.iubenda.com/terms-and-conditions/28069395">T&Cs</DropdownItem>
            <DropdownItem href="https://www.iubenda.com/privacy-policy/28069395">Privacy Policy</DropdownItem>
            <DropdownItem href="https://www.iubenda.com/privacy-policy/28069395/cookie-policy">Cookie Policy</DropdownItem>
{/*
            <DropdownItem href="">Copyright Policy</DropdownItem>
*/}
            <DropdownItem href="/contact_us">Contact</DropdownItem>
          </NavDropdown>
        </Nav>

      </Navbar>

    </div>
  );
}