/* global navigator */

import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import { FaPaperPlane } from 'react-icons/fa';

export default function InviteButton({username}) {

    const [message, setMessage] = useState("Invite");

    return (
        <div style={{width: '18rem'}}>
            <Button
                onClick={async () => {

                    if (navigator.share) {
                        navigator.share({
                            title: 'Building Online',
                            text: "Join me now on Building Online and let's grow our network!",
                            // url: 'https://www.buildingonline.co.uk/',
                            url: 'https://www.buildingonline.co.uk/?invite=' + username,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                    } else {
                        // console.log('you can only send an email to share');
                        setMessage("NOT YET SUPPORTED");
                        alert("Not yet supported on your operating system.");
                    }

                }}
                variant="light"
                block
                style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems:'center'}}
            >
                <h5 style={{color: '#42aaf5', fontFamily: "Verdana"}}>
                    {message}
                </h5>
                <FaPaperPlane size={20} style={{color: 'grey'}}/>
            </Button>
         
        </div>
    );
}