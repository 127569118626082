import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { FaBars, FaArrowLeft } from 'react-icons/fa';
import navbar_logo from '../../../images/BOL_navbar_logo_cropped.jpg';

export default function MyNavbar({history, newLogin, onMenuScreen, hideButtons}) {
    return(
        <Navbar style={{borderBottom: '10px #F3F3F2 solid'}}>
            <Navbar.Brand style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                {newLogin || hideButtons ? 
                    <Button
                        variant="outline-light"
                        style={{color: '#42aaf5', borderColor: '#42aaf5'}}
                    >
                        <FaArrowLeft/>
                    </Button>
                :
                    <Button
                        variant="outline-light"
                        onClick={() => history.goBack()}
                        style={{color: '#42aaf5', borderColor: '#42aaf5'}}
                        data-testid="BackButton"
                    >
                        <FaArrowLeft/>
                    </Button>
                }
                
                <img src={navbar_logo} alt="logo" height="auto" width="175"/>

                {onMenuScreen || hideButtons ? 
                    <Button
                        variant="outline-light"
                        style={{color: '#42aaf5', borderColor: '#42aaf5'}}
                    >
                        <FaBars/>
                    </Button>
                :
                    <Button
                        variant="outline-light"
                        onClick={() => history.push({pathname: '/menu'})}
                        style={{color: '#42aaf5', borderColor: '#42aaf5'}}
                        data-testid="MenuButton"
                    >
                        <FaBars/>
                    </Button>
                }
            </Navbar.Brand>
        </Navbar>
    );
}