import { API, graphqlOperation } from 'aws-amplify';
import { getUser } from '../graphql/queries';
import { createConnection, deleteRequest, sendNotification } from '../graphql/mutations';
import stream from 'getstream';

export default async function acceptConnectionRequest(contactUsername, username, theirEmail, text){

    function update(firstUser, secondUser, status){
        API.graphql(graphqlOperation(createConnection, { input: {
            id: `${firstUser}-${secondUser}`,
            userConnectionsId: firstUser,
            connectionUserId: secondUser
        }}));
    }

    async function follow(user1, user2){
        const user1Token = (await API.graphql(graphqlOperation(getUser, {id: user1}))).data.getUser.feedAuthToken;
        const user2Token = (await API.graphql(graphqlOperation(getUser, {id: user2}))).data.getUser.feedAuthToken;
        const client1 = await stream.connect('ghh53eh4dzjg', user1Token,  '57538');
        const client2 = await stream.connect('ghh53eh4dzjg', user2Token,  '57538');
        await client1.feed('user', client1.userId).follow('user', client2.userId);
        await client2.feed('user', client2.userId).follow('user', client1.userId);
    }

    try {
    
        // need to create the relationship both ways
        await update(username, contactUsername, "Connected");
        await update(contactUsername, username, "Connected");

        await API.graphql(graphqlOperation(deleteRequest, {input: {id: `${username}-${contactUsername}`}}));
        await API.graphql(graphqlOperation(deleteRequest, {input: {id: `${contactUsername}-${username}`}}));

        await follow(username, contactUsername);

        // I needed to update IAM permissions for the role
        await API.graphql(graphqlOperation(sendNotification, {email: theirEmail, text: text}));

    } catch(error){
        console.log(error);
    }
    return;
}