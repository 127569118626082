import React from 'react';
import MenuLinks from '../Links';
import Navbar from '../../lib/Navbar';

export default function MenuScreen({history, location, username}) {
    return (
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column'}}>
            <div style={{minHeight: '100vh', width: '100%'}}>
                <Navbar history={history} onMenuScreen={true}/>
                <div style={{paddingTop: 60, paddingBottom: 60 }}>
                    <MenuLinks username={username}/>
                </div>
           </div>
       </div>
    );
}