import React, {useState} from 'react';

import { Button } from 'react-bootstrap';
import { S3Image } from 'aws-amplify-react';
import ContactPicture from './ContactPicture';

function ShowPicker(username){
    return(
        <S3Image 
            theme={{ photoImg: {position: 'relative', width: '0%'} }}
            imgKey={username}
            picker
        />
    );
}

export default function ProfilePicture({username, showProfilePicture}) {
    const [updateProfilePicture, setUpdateProfilePicture] = useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 20}}>
            {updateProfilePicture ?
                ShowPicker(username)
            :
                <div style={{ width: '200px', height: '200px'}}>
                    <ContactPicture id={username}/>
                </div>

            }
            <Button variant="primary" onClick={() => setUpdateProfilePicture(!updateProfilePicture)} style={{marginTop: 30}}>
                {updateProfilePicture ? "Stop Updating": "Update Profile Picture"}
            </Button>
        </div>
    );
}