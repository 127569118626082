export default function getAvailabilityColor(actual_availability){
    switch(actual_availability){
        case 'Available Immediately':
            return '#39f032';
        case 'Available Soon':
            return '#e9f507';
        case 'Not Available':
            return '#e81f1a';
        case 'Not Looking':
            return 'lightgrey';
        default:
        // code block
    }
}