import React from 'react';
import getAvailabilityColor from '../../../functions/lib/getAvailabilityColor';
import getFormattedDate from '../../../functions/lib/getFormattedDate';
import getActualAvailability from '../../../functions/lib/getActualAvailability';

function Availability({actualAvailability}){
    return (
        <p style={{
            borderBottom: `5px solid ${getAvailabilityColor(actualAvailability)}`,
            padding: '0 0 4px',
            color: getAvailabilityColor(actualAvailability)
        }}>
            {actualAvailability.toUpperCase()}
        </p>
    );
}

export default function ContactDetails({contact, children}) {

    const {firstName, lastName, postcodeRegion, occupation, company, workType, availability, availabilityDate, furtherInfo} = contact;

    if(contact == null) return <div/>;

    const actualAvailability = getActualAvailability(availability, availabilityDate, getFormattedDate());

    return (
        <div>

            <div style={{backgroundColor:'#edf8ff'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: 30, paddingTop: 0, maxWidth: 800, margin: "0 auto"}}>
                    <div style={{paddingTop: 100, flex: 1}}>
                        <h5 style={{fontWeight: 'bold'}}>{`${firstName} ${lastName}`}</h5>
                        <p style={{color: 'grey', marginTop: -5}}>{postcodeRegion}</p>
                        <h6>{occupation}</h6>
                        <h6>@ {company}</h6>
                    </div>
                    <div style={{flex: 1}}>
                        <div style={{marginTop: 10, display:'flex', justifyContent: 'space-between'}}>
                            <p/>
                            <div>
                                <Availability actualAvailability={actualAvailability}/>
                                <div style={{marginTop: 50}}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{maxWidth: 800, margin: "0 auto"}}>
                <div style={{padding: 30}}>
                    <h6 style={{color: '#33a2e8'}}>About me</h6>
                    <p>{furtherInfo}</p>
                </div>
                <div style={{padding: 30, paddingTop: 0}}>
                    <h6 style={{color: '#33a2e8'}}>Availability</h6>
                    <div style={{marginTop: 10, display:'flex', justifyContent: 'space-between'}}>
                        <Availability actualAvailability={actualAvailability}/>
                        <p/>
                    </div>
                    { (actualAvailability === 'Available Soon') && <p>Availability date: {availabilityDate}</p>}
                    <p>Looking for positions in: {workType}</p>
                </div>
            </div>

        </div>
    );
}