import { API, graphqlOperation } from 'aws-amplify';
import { updateUser } from '../graphql/mutations';

export default async function addEmailToUserInfo(userDetails){
  try {
    const {username, email} = userDetails;
    await API.graphql(graphqlOperation(updateUser, {input: {id: username, email: email}}));
    console.log('Email added');
    return;
  } catch (error){
    console.log("adding email failed with error:", error);
  }
}