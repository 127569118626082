import React from 'react';

// import LandingPage from '../components/LandingPage';
// import queryString from 'query-string';

export default function ContactUsScreen({location}) {

  return(
    <div style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        padding: 100,
        paddingTop: 100
    }}>
        <div style={{
            maxWidth: 800,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
        }}>
            <h4 style={{color: '#42b3f5', fontWeight: 'normal'}}>Contact buildingonline.co.uk</h4>
            <h6 style={{color: '#42b3f5', marginBottom: 30}}>Connecting Construction | Building Relationships</h6>
            
            <div style={{textAlign: 'left'}}>
                <h5 style={{color: '#42b3f5', marginBottom: 30, marginTop: 60}}>Enquiries</h5>
                <p>General: <span style={{color: '#42b3f5'}}>info@buildingonline.co.uk</span></p>
                <p>Advertising: <span style={{color: '#42b3f5'}}>sales@buildingonline.co.uk</span></p>
                <p>GDPR: <span style={{color: '#42b3f5'}}>dataprotection@buildingonline.co.uk</span></p>

                <h5 style={{color: '#42b3f5', marginBottom: 30, marginTop: 60}}>Registered Address</h5>
                <p>Building Online Limited is a limited company registered in England and Wales No. 06256134.</p>
                <p>Building Online Limited, Unit 9, Brenton Business Complex, Bond Street, Bury, BL9 7BE, UK.</p>
            </div>
        </div>
    </div>
  );
}