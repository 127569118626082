// import React from 'react';
import React, {useEffect, useState} from 'react';

import MenuScreen from '../components/Menu/Screen';
import getUsername from '../impureFunctions/getUsername';

/*
function initialiseTawkTo(){
    const s1=document.createElement("script");
    s1.id = "tawkToScript";
    s1.async=true;
    s1.src='https://embed.tawk.to/5d7e969f9f6b7a4457e1cb3c/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    document.body.appendChild(s1);
}
*/

export default function Menu({history, location}) {

    const [username, setUsername] = useState(null);

    useEffect(() => {
      async function onScreenStartUp(){
        setUsername(await getUsername());
      }
      onScreenStartUp();
    }, []);

    useEffect(() => {
        // window.Tawk_API ? window.Tawk_API.showWidget() : initialiseTawkTo();

        return function cleanup() {
            if(window.Tawk_API) window.Tawk_API.hideWidget();
        };
    }, []);

    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
            <MenuScreen history={history} location={location} username={username}/>
        </div>
    );
}