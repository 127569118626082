import React from 'react';
import { Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';

export default function SignOutButton() {
    return (
        <div style={{width: '10rem'}}>
            <Button
                onClick={async () => {
                    try {
                        await Auth.signOut();
                        console.log("App logout completed successfully");
                    } catch(error){
                        console.log("Logout failed with exception:", error );
                    }
                }}
                variant="primary"
                block
                style={{
                    alignText:'center', 
                    marginBottom: 40,
                }}
            >
                <h5 style={{fontFamily: "Verdana"}}>
                    Sign Out
                </h5>
            </Button>
        </div>
    );
}