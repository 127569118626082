import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function DeletePostButton({authorId, username, onClick}){ 
  if(authorId === username){
    return (
      <Button
        variant="outline-dark"
        size="sm"
        onClick={onClick}
      >
        Delete
      </Button>
    );
  }
  
  return <div/>;
}

DeletePostButton.propTypes = {
  authorId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};