import React, {useState, useEffect} from 'react';
import ContactDetails from '../components/Contact/Details';
import getUsername from '../impureFunctions/getUsername';
import getUserDetails from '../impureFunctions/getUserDetails';
import Navbar from '../components/lib/Navbar';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ContactPicture from '../impureComponents/ContactPicture';
import goToScreen from '../impureFunctions/goToScreen';
import { Button } from 'react-bootstrap';

export default function ProfileNonEditable({location, history}) {

    const [userDetails, setUserDetails] = useState(null);
    const [username, setUsername] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const username = await getUsername();
            setUsername(username);
            setUserDetails(await getUserDetails(username));
        };
        
        fetchData();

        if(window.Tawk_API) window.Tawk_API.hideWidget();
        
    }, []);

    return (
        <div style={{minHeight: '100vh', width: '100vw'}}>
            <Navbar history={history}/>
            
            <div style={{margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button variant="primary" onClick={goToScreen(history, 'profile')}>Back to your editable profile</Button>
            </div>
            {userDetails &&
                <div>
                    <div style={{maxWidth: 750, margin: "0 auto"}}>
                        <div style={{margin: 50, marginBottom: -45, maxWidth: "25vw", maxHeight: "25vw", width: '150px', height: '150px'}}>
                            <ContactPicture id={username}/>
                        </div>
                    </div>

                    <ContactDetails contact={userDetails}/>
                </div>
            }

        </div>
    );
}