import { API, graphqlOperation } from 'aws-amplify';
import { createUser, createChatUser, createFeedUser } from '../graphql/mutations';

export default async function createNewUser(userDetails){
  try {
    const {username, email} = userDetails;
    const feedAuthToken = (await API.graphql(graphqlOperation(createFeedUser, {username: username}))).data.createFeedUser;
    console.log('Feed user created');
    const authToken = (await API.graphql(graphqlOperation(createChatUser, {username: username}))).data.createChatUser;
    console.log('Chat user created');
    await API.graphql(graphqlOperation(createUser, {input: {id: username, email: email, chatAuthToken: authToken, feedAuthToken: feedAuthToken}}));
    console.log('App user created');
    return;
  } catch (error){
    console.log("createNewUser failed with error:", error);
  }
}