import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import ContactPicture from '../../../impureComponents/ContactPicture';

import getAvailabilityColor from '../../../functions/lib/getAvailabilityColor';
import getFormattedDate from '../../../functions/lib/getFormattedDate';
import getActualAvailability from '../../../functions/lib/getActualAvailability';


export default function UserListItem({contact}) {

  const {user, status} = contact;
  const {id, firstName, lastName, occupation, company, availability, availabilityDate} = user;

  const actualAvailability = getActualAvailability(availability, availabilityDate, getFormattedDate());

  return(
    <Link
      to={{pathname: "/contact", search: `?contact=${id}` }}
      style={{textDecoration: 'none', marginBottom: 5, width: '100%', marginRight: 20}}
    >
      {status === 'Received' && <Badge variant="success" style={{marginRight: -10}}>CONNECTION REQUEST</Badge>}
      <ListGroup.Item
        action
        style={{
            display:'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            borderRadius: "15px",
            
        }}
      >
        <div style={{display:'flex', alignItems: 'center'}}>

          <div style={{ minWidth: '80px', width: '80px', height: '80px',  marginRight: "50px"}}>
            <ContactPicture id={id} border={getAvailabilityColor(actualAvailability)}  />
          </div>

          <div style={{lineHeight: 0.9}}>
            <p style={{marginBottom: "10px"}}>{firstName + ' ' + lastName}</p>
            <p style={{marginBottom: "10px", color:"grey"}}>{occupation}</p>
            <p style={{marginBottom: "5px"}}><b>{company}</b></p>    
          </div>
        </div>

      </ListGroup.Item>
    </Link>
  );
}
