// https://github.com/talkjs/talkjs-examples/tree/master/react/basic-example

import React, { useEffect, useRef } from 'react';
import Talk from 'talkjs';
import queryString from 'query-string';
import Navbar from '../components/lib/Navbar';
import getUsername from '../impureFunctions/getUsername';
import { getUser } from '../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify';

async function getName(username){
    const user = await (await API.graphql(graphqlOperation(getUser, {id: username}))).data.getUser;
    const {email, firstName, lastName, chatAuthToken} = user;
    return {email: email, name: `${firstName} ${lastName}`, chatAuthToken: chatAuthToken};
}

export default function Main({history, location}) {
    const messagesEndRef = useRef();

    useEffect(() => {
        async function startChat(location){

            const contactId = queryString.parse(location.search).contactid;
            var conversationId = queryString.parse(location.search).conversationId;

            const username = await getUsername();
            // const myPhotoUrl = await Storage.get(username);
            const myPhotoUrl = await Storage.get("profile-pics/" + username + ".jpeg");

            const myDetails = await getName(username);
            
            var contactDetails;
            var contactPhotoUrl;
            if(contactId){
                contactDetails = await getName(contactId);
                contactPhotoUrl = await Storage.get("profile-pics/" + contactId + ".jpeg");
            }

            var inbox;
            Talk.ready.then(() => {
                const me = new Talk.User({
                    id: username,
                    name: myDetails.name,
                    email: myDetails.email,
                    photoUrl: myPhotoUrl,
                    role: "user"
                    // welcomeMessage: "Hey there! How are you? :-)"
                });

                if (!window.talkSession) {
                    window.talkSession = new Talk.Session({
                        appId: process.env.REACT_APP_TALKJS_APPID || 't7BeH1Yx',
                        me: me,
                        signature: myDetails.chatAuthToken
                    });
                }
                
                var conversation;
                if(contactId){
                    const other = new Talk.User({
                        id: contactId,
                        name: contactDetails.name,
                        email: contactDetails.email,
                        photoUrl: contactPhotoUrl,
                        role: "user"
                        // welcomeMessage: "Hey there! Love to chat :-)"
                    });
    
                    // You control the ID of a conversation. oneOnOneId is a helper method that generates
                    // a unique conversation ID for a given pair of users. 
                    conversationId = Talk.oneOnOneId(me, other);
                
                    conversation = window.talkSession.getOrCreateConversation(conversationId);
                    conversation.setParticipant(me);
                    conversation.setParticipant(other);
                } else {
                    conversation = window.talkSession.getOrCreateConversation(conversationId);
                }

                let chatView = messagesEndRef.current;
            
///////
                // // show chat converstation with back button to inbox
                // inbox = window.talkSession.createInbox({
                //     selected: conversation
                // });
                // inbox.mount(chatView);
                
///////             
                // // show just the inbox
                // inbox = window.talkSession.createInbox();
                // inbox.mount(chatView);

///////         
                // just chatbox (without back button to inbox)
                inbox = window.talkSession.createChatbox(conversation);
                inbox.mount(chatView);                

            })
            .catch(e => console.error(e));

            if(window.Tawk_API) window.Tawk_API.hideWidget();

            // Specify how to clean up after this effect:
            return function cleanup() {
                inbox.destroy();
            };
          
        }
        startChat(location);
    }, [location]);

    return (
        <div style={{minHeight: '100vh', width: '100vw', marginBottom: 40}}>
            <Navbar history={history}/>

            <div style={{minHeight: '90vh', width: '100vw', display:'flex', justifyContent: 'center', padding: 20}}>
                <div ref={messagesEndRef} style={{width: '100vw', height: '90vh', minHeight: '500px'}}/>
            </div>

        </div>
    );

}