import React from 'react';

import { Button } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import { Link } from "react-router-dom";

function SendContactRequestButton({sendContactRequest}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginTop: 10, marginBottom: 20}}>
            <Button
                variant="outline-primary"
                onClick={sendContactRequest}
            >
                <FaUserPlus size={30} data-testid="SendContactRequestButton"/>
                <p style={{marginTop:10}}>ADD CONTACT</p>
            </Button>
        </div>
    );
}

function ChatToContactButton({contactid}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginBottom: 20}}>
            <Link to={{pathname: '/chat', search: `?contactid=${contactid}`}}>
                <Button data-testid="ChatToContactButton">Message</Button>
            </Link>
        </div>
    );
}

function ContactRequestButton({acceptContactRequest, declineConnectionRequest}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginBottom: 20}}>
            <Button
                variant="outline-primary" 
                data-testid="AcceptContactRequestButton"
                onClick={acceptContactRequest}
                style={{marginBottom: 40}}
            >
                ACCEPT
            </Button>
            <Button
                variant="outline-secondary" 
                onClick={declineConnectionRequest}
            >
                DECLINE
            </Button>            
        </div>
    );
}

function AwaitingRequestAcceptance() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginBottom: 20}}>
            <Button>
                <p>Contact request awaiting approval</p>
            </Button>
        </div>
    );
}

export default function ContactOptions({relationship, sendContactRequest, acceptContactRequest, declineConnectionRequest, contactid}) {

    return (
        <div>
            {relationship === null && <SendContactRequestButton sendContactRequest={sendContactRequest}/>}
            {relationship === "Connected" && <ChatToContactButton contactid={contactid}/>}
            {relationship === "Received" && <ContactRequestButton acceptContactRequest={acceptContactRequest} declineConnectionRequest={declineConnectionRequest}/>}
            {relationship === "Requested" && <AwaitingRequestAcceptance/>}
        </div>
    );
}