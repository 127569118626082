import { Auth } from 'aws-amplify';

export default async function getUsername(email = false){
    const authenticated_user = await Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    });

    const {username, attributes} = authenticated_user;

    return email ? {username: username, email: attributes.email } : username;
    // return email ? {username: 'Philgold', email: attributes.email } : 'Philgold';
}// 