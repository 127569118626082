import stream from 'getstream';
import { API, graphqlOperation } from 'aws-amplify';
import removeEmptyStringAttributesFromObject from '../functions/Profile/removeEmptyStringAttributesFromObject';
import { updateUser } from '../graphql/mutations';

export default async function updateUserDetails(user, username, setShowLoader, feedAuthToken, newuser, chatAuthToken){

    const {firstName, lastName, namePrefix, postcodeRegion, occupation, company, furtherInfo} = user;

    const input = {
        id: username,
        firstName: firstName,
        lastName: lastName,
        fullNameLowerCase: firstName.toLowerCase() + ' ' + lastName.toLowerCase(),
        namePrefix: namePrefix,
        postcodeRegion: postcodeRegion,
        occupation: occupation,
        company: company,
        furtherInfo: furtherInfo
    };

    await API.graphql(graphqlOperation(updateUser, {input: removeEmptyStringAttributesFromObject(input)}));
    
    const client = stream.connect('ghh53eh4dzjg', feedAuthToken, '57538');
    
    // newuser === "true" ?
    //     await client.user(client.userId).getOrCreate({name: `${firstName} ${lastName}`, occupation: occupation})
    // :
        await client.user(client.userId).update({name: `${firstName} ${lastName}`, occupation: occupation});

    return;
}
