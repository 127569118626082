import { API, graphqlOperation } from 'aws-amplify';
import { createRequest, sendNotification } from '../graphql/mutations';

export default async function createConnectionRequest(contactUsername, username, email, text){

    function create(firstUser, secondUser, status){
        API.graphql(graphqlOperation(createRequest, { input: {
            id: `${firstUser}-${secondUser}`,
            userRequestsId: firstUser,
            requestUserId: secondUser,
            status: status
        }}));
    }

    try{   
        await create(contactUsername, username, "Received");
        await create(username, contactUsername, "Requested");

        // I needed to update IAM permissions for the role
        await API.graphql(graphqlOperation(sendNotification, {email: email, text: text}));

    } catch (error){
        console.log(error);
    }

    
}