import React from 'react';

import LandingPage from '../components/LandingPage';
import queryString from 'query-string';

export default function LandingPageScreen({location}) {

  const invitedBy = queryString.parse(location.search).invite;

  return(
    <div style={{minHeight: '100vh', width: '100vw'}}>
      <LandingPage invitedBy={invitedBy}/>
    </div>
  );
}