import getUsername from './getUsername';
import { API, graphqlOperation } from 'aws-amplify';
import { getUser1 } from '../graphql/queries';

export default async function getConnections(nextToken){
    const username = await getUsername();
    const connections = (await API.graphql(graphqlOperation(getUser1, {id: username, nextToken: nextToken}))).data.getUser.connections;
    return {
        nextToken: connections.nextToken,
        connections: connections.items,
        hasMore: (connections.nextToken === null) ? false : true
    };
}
