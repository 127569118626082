import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ListGroup } from 'react-bootstrap';
import ListItem from '../ListItem';
import PropTypes from 'prop-types';

export default function UserList({users, loadFunc, hasMore}) {

    const items = users.map((contact, index) => <ListItem key={index} contact={contact} />);

    return (
        <InfiniteScroll
          style={{padding: 10}}
          pageStart={0}
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={<div className="loader" key={0}>Loading ...</div>}
        >
          <ListGroup>
            {items}
          </ListGroup>
        </InfiniteScroll>        
    );
}

UserList.propTypes = {
  users: PropTypes.array.isRequired,
  loadFunc: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired
};