import extractStatusFromPost from './extractStatusFromPost';

function mapAvailabilityToColour(availability){
  switch(availability){
    case 'Available Immediately':
      return '#39f032';
    case 'Available Soon':
      return '#e9f507';
    case 'Not Available':
      return '#e81f1a';
    case 'Not Looking':
      return 'lightgrey';
    default:
      // code block
  }
  return;
}

export default function postStyle(status){
    if(status.includes('UPDATED WORK STATUS')){
        const availability = extractStatusFromPost(status);
        return {borderBottom: '7px solid ' + mapAvailabilityToColour(availability), display: 'flex', justifyContent: 'space-between'};
    }

    return {display: 'flex', justifyContent: 'space-between'};
}