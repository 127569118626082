import { API, graphqlOperation } from 'aws-amplify';
import { getRequest } from '../graphql/queries';

function doesRequestExist(request){
    return (request === null) ? false : true;
}

export default async function getConnectionRequest(username, contactUsername){
    const request = (await API.graphql(graphqlOperation(getRequest, {id : username + '-' + contactUsername} ))).data.getRequest;
    var requestCopy = Object.assign({}, request);
    requestCopy.exists = doesRequestExist(request);
    return requestCopy;
}