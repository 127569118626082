export default class dateString {
  constructor(dateString){
    this._dateString = dateString;
  }

  get date_numbers_array(){
    return this._dateString.split("-").reverse();
  }

  get day(){
    return parseInt(this.date_numbers_array[2], 10);
  }
  
  get month(){
    return parseInt(this.date_numbers_array[1], 10) - 1;
  }
  
  get year(){
    return parseInt(this.date_numbers_array[0], 10);
  }
  
  get toDate(){
    if(this._dateString === null){ return new Date() }
    return new Date(this.year, this.month, this.day);
  }
}